import React from "react";
import { Routes } from "../enum";
import CustomLink from "../components/CustomLink";
import InnerHeader from "../components/InnerHeader";
import { Container } from "react-bootstrap";

function About() {
  return (
    <div>
      <InnerHeader title="About Us" />
      <div className="sec_pad">
        <Container>
          <p>
            We are Happyman Housing Limited, an Estate Firm registered as a
            Limited Company under the Laws of England and Wales. We trade under
            the name and style of Happyman Housing. Our office is in London
            where we offer our special range of services to Landlords and
            function as Property Investors. Happyman Housing parades experienced
            staff, and we pride ourselves on possessing relevant expertise in
            our chosen areas of operation. We are positioned to take on
            properties in London and in boroughs surrounding London only, with a
            non-negotiable commitment to maintaining the highest standards in
            our areas of service/engagement. We offer guaranteed rents to
            Landlords under our “Guaranteed Rent Scheme”. This scheme brings
            peace of mind to the Landlord, knowing that they will always receive
            income from their properties even during void periods, or when
            tenants default. Additionally, we can subject to agreement,
            transform your properties into a “House in Multiple Occupation”
            (HMO) where it meets the relevant requirements. We can (subject to
            agreement) approach the appropriate authorities to obtain the
            required license where your property qualifies. Happyman Housing
            parades experienced staff and we pride ourselves on possessing
            relevant expertise in our chosen areas of operation. We pride
            ourselves on rendering excellent customer service. We are prepared
            to listen to you to identify your needs and work with you to meet
            set goals. That is why we are open Monday to Saturday 9.00 am to
            5.00 pm, and are also available on our out-of-hours numbers from
            5.00 pm to 7.00 pm Monday to Saturday, to ensure that we are readily
            available to entertain all your enquiries.
            <CustomLink linkTo={Routes.Contact}>Contact Us</CustomLink>
          </p>
        </Container>
      </div>
    </div>
  );
}

export default About;
