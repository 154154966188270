import React from "react";
import { Container } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";

function ZeroCommission() {
  return (
    <div>
      <InnerHeader title="0% Commission" />
      <div className="sec_pad">
        <Container>
          <p>
            Under our Guaranteed Rent Scheme, landlords pay no commission at
            all. You are not charged for the services we render. We pay you
            whatever rent we agree with you without deducting any commission for
            the services we render. That is why it is a 0% Commission scheme.
          </p>
        </Container>
      </div>
    </div>
  );
}

export default ZeroCommission;
