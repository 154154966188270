import React from "react";
import { Routes, AdditionalInformations } from "../enum";
import CustomLink from "../components/CustomLink";
import { Container } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";

function AdditionalInfo() {
  return (
    <div>
      <InnerHeader title="Additional Information" />
      <div className="sec_pad">
        <Container>
          <p>
            <ol>
              {AdditionalInformations.map((addInfo) => (
                <li>{addInfo}</li>
              ))}
            </ol>
          </p>
          <p>
            If you require additional information and it is not here, please{" "}
            <CustomLink linkTo={Routes.Contact}>contact us</CustomLink>
          </p>
        </Container>
      </div>
    </div>
  );
}

export default AdditionalInfo;
