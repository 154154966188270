import ProcessHeading from "../components/ProcessHeading";

function Cookies() {
  return (
    <div>
      <h2>Cookies</h2>
      <p>
        When you use our website, our website is designed to send cookies to
        your computer. Cookies is a piece of data sent from our website to your
        computer when you visit our site; and this information is stored on your
        computer by the user’s web browser whilst you are browsing. Cookies help
        make this website work and provide information to us about how users
        interact with our site. We use this information to improve our website.
        It helps our website to remember you when you come back to the website.
      </p>
      <ProcessHeading heading="Third Party Websites Cookies" />
      <p>
        There are other times when third-party websites cookies may be sent to
        your website if you access a third-party website through our website or
        where there are links to such websites from our website. In such cases,
        your information is governed by their privacy policies, and you may use
        the privacy policies of such third-party websites to opt-out from such
        information collection.
      </p>
      <ProcessHeading heading="How long we retain your Data" />
      <p>
        Data automatically collected by us, or provided by you are retained for
        as long as your account remains active or as required to provide
        services to you, but in accordance with the laws guiding retention of
        data in the United Kingdom. Data held on you may also be deleted when
        you decide to opt-out or require that they be deleted. Once we deem any
        data held as no longer necessary for relevant uses, we may at our
        discretion, delete or amend any such data held on our systems.
      </p>
      <ProcessHeading heading="Right of access to your data" />
      <p>
        You can exercise your “Subject Access Right” to ensure that your
        personal data is being processed lawfully. Your subject access right can
        be exercised in accordance with data protection laws and regulations.
        Any subject access request must be made in writing to the Data
        controller either by email (contact us link below) or by post at the
        address provided in the contact section of this privacy policy.
      </p>
      <ProcessHeading heading="Responsibility for Policy" />
      <p>
        Responsibility for this policy, including an annual documented review,
        is that of our Data Controller. Requests regarding all data collected or
        data retained pursuant to this privacy policy, including but not limited
        to the Data Controller.
      </p>
      <ProcessHeading heading="Contacting us" />
      <p>
        If you have any enquiries/concerns relating to information held by us,
        please contact us: info@happymanhousinhg.com
      </p>
      <p>or write to:</p>
      <p>
        Data Controller Happyman Housing Limited 8 Camberwell Green London SE5
        7AF
      </p>
    </div>
  );
}

export default Cookies;
