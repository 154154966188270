import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Routes } from "../enum";
import logo from "../assets/logo.png";
import NavigationBar from "./NavigationBar";
import TranslateWidget from "../components/TranslateWidget";

function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary main_header">
      <Container>
        <Navbar.Brand as={Link} to={Routes.Home}>
          <img src={logo} alt="Happyman Housing Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <NavigationBar />
        </Navbar.Collapse>
      <TranslateWidget />
      </Container>
    </Navbar>
  );
}

export default Header;
