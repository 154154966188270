import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function RouteError() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={9}></Col>
        <h1>Oops! An error occurred.</h1>
        <p>
          Sorry for the inconvenience. Please refresh the page or try again
          later.
        </p>
      </Row>
    </Container>
  );
}

export default RouteError;
