import React from "react";
import { Container } from "react-bootstrap";
import bannerVideo from "../assets/happy-man.mp4";

function InnerHeader({title}) {
  return (
    <div>
      <div className="inner_head">
        <Container>
          <h1>{title}</h1>
        </Container>
        <video  autoPlay loop muted>
          <source src={bannerVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default InnerHeader;
