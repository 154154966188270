import React from "react";
import { Container } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";

function Home() {
  return (
    <div>
      <InnerHeader title="Home" />
      <div className="sec_pad">
        <Container>
          <p>
            We are Happyman Housing Limited, an Estate Firm registered as a
            Limited Company under the Laws of England and Wales...
          </p>
        </Container>
      </div>
    </div>
  );
}

export default Home;
