import { Routes } from "./enum";

const RoutesArray = [
  {
    name: "Home",
    path: Routes.Default,
  },
  {
    name: "About Us",
    path: Routes.About,
  },
  {
    name: "More",
    children: [
      {
        name: "Services",
        path: Routes.Services,
        dropdown: true,
      },
      {
        name: "Process",
        path: Routes.Process,
        dropdown: true,
      },
      {
        name: "Additional Information",
        path: Routes.AdditionalInfo,
        dropdown: true,
      },
      {
        name: "0% Commission",
        path: Routes.ZeroCommission,
        dropdown: true,
      },
    ],
  },
  {
    name: "Join Us",
    path: Routes.JoinUs,
  },
  {
    name: "Contact Us",
    path: Routes.Contact,
  },
  {
    name: "Privacy",
    path: Routes.Privacy,
    hiddenRoute: true,
  },
  {
    name: "Cookies",
    path: Routes.Cookies,
    hiddenRoute: true,
  },
];

export default RoutesArray;
