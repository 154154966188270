import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import RoutesArray from "../routeArray";

const getNavigationDropDown = (routes) => {
  return (
    <NavDropdown title={routes.name} id="basic-nav-dropdown" key={routes.name}>
      {routes.children.map((route) => (
        <NavDropdown.Item key={route.path} as={Link} to={route.path}>
          {route.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

const generateNavigationBar = (routes) =>
  routes.map((route) =>
    route.children ? (
      getNavigationDropDown(route)
    ) : !route.hiddenRoute ? (
      <Nav.Link key={route.path} as={Link} to={route.path}>
        {route.name}
      </Nav.Link>
    ) : null
  );

const navigationBar = generateNavigationBar(RoutesArray);

function NavigationBar() {
  return <Nav className="me-auto">{navigationBar}</Nav>;
}

export default NavigationBar;
