import React from "react";
import { Container } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";

function Services() {
  return (
    <div>
      <InnerHeader title="Services" />
      <div className="sec_pad">
        <Container>
          <p>
            We offer guaranteed rents to Landlords under our “Guaranteed Rent
            Scheme”...
          </p>
        </Container>
      </div>
    </div>
  );
}

export default Services;
