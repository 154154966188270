export const ProjectName = "Happyman Housing Limited";

export const ProjectAddress = "8 Camberwell Green, London, SE5 7AF";

export const ProjectPhone = "+4402036334717";

export const ProjectEmail = "info@happymanhousing.com";

export const currentYear = new Date().getFullYear();

export const apiUrl = "https://happymanhousing.com/";

export const Routes = {
  Default: "/",
  About: "/about",
  Services: "/services",
  Process: "/process",
  AdditionalInfo: "/additional-info",
  ZeroCommission: "/zero-commission",
  JoinUs: "/join-us",
  Contact: "/contact",
  Privacy: "/privacy",
  Cookies: "/cookies",
};

export const AdditionalInformations = [
  "We accept 1 to 6 (or above) bedroom Flats, Houses, and Bungalows within Inner and Outer London Boroughs.",
  "Flexible 1 to 5 years agreement.",
  "Rents are paid monthly.",
  "We pay the landlord during void periods, and even if the Tenant defaults.",
  "Rents are paid as and when due, payment of rent is on due dates and unaffected by weekends, bank holidays, or festivities.",
  "Rent payable to landlords depends on the type of property, the location, the condition of the property, and the number of rooms in it.",
  "We maintain the property 24/7 (subject to contract), and subject to the content of our agreement with you, the landlord will not have to bear the costs of some repairs (except what we classify as major repairs, for example, Roofing, Boiler replacement, complete rewiring of the property, etc.).",
  "If you cannot provide the relevant documentation, we can (subject to agreement) obtain them on your behalf and at your own cost, unless an agreement is reached otherwise.",
  "It is not a requirement that the property be furnished. We accept properties unfurnished.",
  "At the end of the lease period, the property is restored to its original state at the time of handing over the property to us.",
];
