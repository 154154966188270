import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { apiUrl } from "../enum";
import InnerHeader from "../components/InnerHeader";

function Contact() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    address: "",
    telephone: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    if (!recaptchaToken) {
      setError("Please complete the reCAPTCHA.");
      return;
    }
    const errors = validateForm(form);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      submitForm();
    } else {
      console.log("Form has errors");
    }
  };

  const submitForm = async () => {
    try {
      const response = await fetch(`${apiUrl}send-request.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });
      console.log(response);

      if (!response.ok) {
        if (response.status === 400) {
          setError("Invalid input. Please check your details and try again.");
        } else if (response.status === 403) {
          setError("Unauthorized request. Please contact support.");
        } else if (response.status === 405) {
          setError("Only POST requests are allowed.");
        } else if (response.status === 429) {
          setError(
            "You are submitting too frequently. Please try again later."
          );
        } else {
          setError("Something went wrong. Please try again later.");
        }
      } else {
        setSuccess("Message sent successfully");
      }
    } catch (error) {
      // setError(
      //   "Failed to submit request. Please check your network connection and try again."
      // );
      setError("We are working on it.");
    }
  };

  const validateForm = (data) => {
    const { name, email, address, telephone, message } = data;
    let errors = {};
    if (!name && !name.trim()) {
      errors.name = "Name is required";
    }
    if (!email && !email.trim()) {
      errors.email = "Email is required";
    } else if (!email && !isValidEmail(email)) {
      errors.email = "Invalid email format";
    }
    if (!address && !address.trim()) {
      errors.address = "Address is required";
    }
    if (!telephone && !telephone.trim()) {
      errors.telephone = "Phone is required";
    } else if (!telephone && !isValidPhone(telephone)) {
      errors.telephone = "Invalid phone number";
    }
    if (!message && !message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidPhone = (phone) => {
    // Simple phone validation regex (10 digits)
    const regex = /^\d{10}$/;
    return regex.test(phone);
  };

  return (
    <div>
      <InnerHeader title="Contact Us" />
      <div className="sec_pad">
        <Container>
          <Row className="justify-content-center">
            <Col sm={9}>
              <div className="card_me conatct_em">
                <h3>Contact Us</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                      required=""
                    />
                    {errors.name && (
                      <span style={{ color: "red" }}>{errors.name}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={form.address}
                      onChange={handleChange}
                      required=""
                    />
                    {errors.address && (
                      <span style={{ color: "red" }}>{errors.address}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Telephone</Form.Label>
                    <Form.Control
                      type="number"
                      name="telephone"
                      placeholder="Telephone"
                      value={form.telephone}
                      onChange={handleChange}
                      required=""
                    />
                    {errors.telephone && (
                      <span style={{ color: "red" }}>{errors.telephone}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Message"
                      name="message"
                      value={form.message}
                      onChange={handleChange}
                      required=""
                    />
                    {errors.message && (
                      <span style={{ color: "red" }}>{errors.message}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={handleRecaptchaChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  {success && <p style={{ color: "green" }}>{success}</p>}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Contact;
