import React from "react";
import { Container } from "react-bootstrap";
import { Routes } from "../enum";
import ProcessHeading from "../components/ProcessHeading";
import CustomLink from "../components/CustomLink";
import InnerHeader from "../components/InnerHeader";

function Process() {
  return (
    <div>
      <InnerHeader title="The Process" />
      <div className="sec_pad">
        <Container>
          <p>
            The first step in creating the relationship is to talk to us. We are
            available to discuss new relationships Mondays to Saturdays 9.00 am
            to 7.00 pm. All you need to do is pick up your phone and call us, or
            you complete the enquiry form on our website, and we will contact
            you within 24 hours.
          </p>
          <p>
            During your call, we would have a brief discussion on the nature of
            the property, where it is located, options available, whether the
            relevant documents are in place, and the way forward.
          </p>
          <p>
            <CustomLink linkTo={Routes.Contact}>Talk to us.</CustomLink>
          </p>
          <ProcessHeading heading="Conduct Joint Inspection" />
          <p>
            Once we have agreed on the way forward, we will then proceed to
            arrange an inspection of the Property (this is subject to your
            convenience). We aim to complete the inspection within 48 hours of
            the initial discussions where practicable.
          </p>
          <ProcessHeading heading="Supply required documents" />
          <p>
            After inspecting the property, we will inspect documents relevant to
            the transaction. The documents we are sure to ask for are those
            required to be in place by law. We will require the following
            documents:
          </p>
          <ol>
            <li>Energy Performance Certificate</li>
            <li>NICEIC Electrical Test Certificate</li>
            <li>Gas Safety Certificate</li>
            <li>Proof of Building Insurance</li>
            <li>
              HMO Licence (where a property is a property in multiple
              occupations)
            </li>
            <li>3 sets of keys</li>
          </ol>
          <p>
            Where the landlord cannot provide the documents listed above, or the
            landlord does not have any of the documents required, we can
            (subject to agreement), help obtain the documents at the landlord’s
            cost.
          </p>
          <ProcessHeading heading="Enter into the relevant agreement" />
          <p>
            Once the first three steps are completed, we proceed to agree. We
            accept properties on a 3 to 5-year basis.
          </p>
          <ProcessHeading heading="Guaranteed Rent Starts" />
          <p>Guaranteed Rent starts, and it is payable monthly.</p>
        </Container>
      </div>
    </div>
  );
}

export default Process;
