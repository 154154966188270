import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "react-bootstrap/Container";

function RootLayout() {
  return (
    <Container className="Main_slite">
      <Header />
      <Outlet />
      <Footer />
    </Container>
  );
}

export default RootLayout;
