import React from "react";
import ProcessHeading from "../components/ProcessHeading";

function Privacy() {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>
        The happymanhousing.com website is owned and operated by Happyman
        Housing Limited, a Company incorporated under the Laws of England and
        Wales with Company Registration Number <strong>12015542</strong>, and
        its Registered Office is at 8 Camberwell Green, London, SE5 7AF. We are
        committed to protecting and preserving the privacy of the users, and
        visitors to our websites.
      </p>
      <p>
        This Privacy Policy sets out how we process, and use any personal data
        you provide to us or that we collect from you through your use or visit
        to our website. We can confirm that we will keep your information secure
        and will comply fully with all applicable UK Data Protection legislation
        and regulations. This Privacy Policy has been drafted in accordance with
        the Laws that operate in the United Kingdom of Great Britain and
        Northern Ireland.
      </p>
      <ProcessHeading heading="Making Change to this Policy." />
      <p>
        We reserve the right to amend, modify, or substitute this privacy policy
        from time to time. When a change or amendment is made to this policy, it
        will be made on the body of this policy (amended version) and the
        amended versions of the privacy policy will be posted on our website.
        Where the privacy policy is replaced altogether, the new policy will be
        posted on our website and this privacy policy will be removed.
      </p>
      <ProcessHeading heading="How we Collect Information" />
      <p>We collect information in two ways:</p>
      <p>
        <ol>
          <li>
            <strong>Automatically</strong>: We may collect information
            automatically using cookies.
            <strong>Provided by you</strong>: We may collect information through
            details directly provided by you during registration, processing of
            your application, acceptance, or in the normal use of our
            services/website.
          </li>
        </ol>
      </p>
      <ProcessHeading heading="Information we may request/collect from you" />
      <p>
        Every information about you that we may require you to enter at the time
        of registering with us, or while processing your application. We may
        request any information that we consider necessary to carrying out the
        services that you require. This required information may change from
        time to time. Information we may request could include, but not limited
        to:
      </p>
      <p>
        <ul>
          <li>Your name</li>
          <li>Your postal address</li>
          <li>Your telephone numbers.</li>
          <li>Your email address</li>
        </ul>
      </p>
      <ProcessHeading heading="How we use information Collected" />
      <p>
        <ol>
          <li>
            For the administration of Services that we provide to you, and our
            clients and to help in the proper management of any account that you
            hold with us; or services that we offer to you.{" "}
          </li>
          <li>
            To identify and properly respond to you when you make any request
            from us.
          </li>
          <li>Detection and Prevention of fraud.</li>
          <li>
            Where you have provided us with your consent to do so, to contact
            you regarding other products/services offered by this website, and
            any other products and services offered by the company operating
            this website.
          </li>
        </ol>
      </p>
      <ProcessHeading heading="With whom we might share your information" />
      <p>
        There are circumstances in which we may share your information or
        receive information about you:
      </p>
      <p>
        a. Staff or other members of our company that require such information
        while carrying out their functions as staff members. b. Third Party
        Rights, Enforcement of Public Policies, Company Rights, and Legal
        Disclosure. We may disclose your personal information if required by law
        or to comply with an order of court or when we believe that disclosure
        is necessary to protect our rights, required for the protection of your
        safety or the safety of others; respond to a government request and/or
        investigate fraud. c. Internet service providers and third-party
        business partners. We may supply your Personal Data to our internet
        service providers and third-party business partners. This is because it
        may be necessary to do this for the proper administration of our
        website. It is also important to kindly note that some of these third
        parties may sometimes be located outside of the United Kingdom; but we
        ensure through a binding agreement that they provide privacy policies
        that are comparable to what is required of us by the Law of the Country
        that we operate. We also make these third parties sign a non-disclosure
        agreement with us. d. Third parties that we may use to collect feedback
        and reviews of our services from you.
      </p>
      <ProcessHeading heading="Sending your information outside the EEA" />
      <p>
        There are situations when some of our third-party partners may be
        outside the EEA, We will only send your personal information to
        providers outside the EEA if those providers are required to protect
        personal information in the same way they would be required to protect
        personal information where they were within the EEA. We will send data
        to such recipient only if:
      </p>
      <p>
        a. The Third-Party recipient has by a written agreement with us, agreed
        to comply with the requirements for standards of personal information
        processing and protection set under the appropriate EEA
        Laws/Regulations. b. Where the European Commission has determined that
        the non-EEA country engages in an adequate and appropriate level of
        protection for personal data. c. Data may be sent to the US in
        accordance with the relevant protection shield.
      </p>
      <ProcessHeading heading="Communications we might send to you." />
      <p>
        There may be circumstances where we need to send you some
        communications. Unless where extremely necessary, we limit our
        communication with you, strictly to Service communications. These
        service communications comprise of communications which:
      </p>
      <p>
        1. We send to you when you require us to carry out a service. These are
        services that you initiate. These services may include password reset
        emails, which you may ask us to send if you do not remember your login
        credentials but want to access our products and services. 2. We send
        immediately after you have registered to access our services; or when
        you cancel your subscription with us. 3. We may send information to
        notify you of payments, acknowledge receipt of payment, or bring the
        existence of an invoice to your attention.
      </p>
    </div>
  );
}

export default Privacy;
