import { createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import RouteError from "./pages/RouteError";
import RootLayout from "./pages/RootLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Process from "./pages/Process";
import AdditionalInfo from "./pages/AdditionalInfo";
import ZeroCommission from "./pages/ZeroCommission";
import JoinUs from "./pages/JoinUs";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Cookies from "./pages/Cookies";
import { Routes } from "./enum";

const RouterConfig = createBrowserRouter([
  {
    path: Routes.Default,
    element: (
      <ErrorBoundary>
        <RootLayout />
      </ErrorBoundary>
    ),
    errorElement: <RouteError />,
    children: [
      { index: true, element: <Home /> },
      { path: Routes.Default, element: <Home /> },
      { path: Routes.About, element: <About /> },
      { path: Routes.Services, element: <Services /> },
      { path: Routes.Process, element: <Process /> },
      { path: Routes.AdditionalInfo, element: <AdditionalInfo /> },
      { path: Routes.ZeroCommission, element: <ZeroCommission /> },
      { path: Routes.JoinUs, element: <JoinUs /> },
      { path: Routes.Contact, element: <Contact /> },
      { path: Routes.Privacy, element: <Privacy /> },
      { path: Routes.Cookies, element: <Cookies /> },
    ],
  },
]);

export default RouterConfig;
