import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import logo from "../assets/footer_logo.png";
import {
  ProjectName,
  ProjectAddress,
  ProjectEmail,
  ProjectPhone,
  currentYear,
} from "../enum";

const handleLinkClick = (event, email) => {
  event.preventDefault();
  window.location.href = `mailto:${email}`;
};

const handlePhoneClick = (event, phone) => {
  event.preventDefault();
  window.location.href = `tel:${phone}`;
};

function Footer() {
  return (
    <footer className="footer_main">
      <Container>
        <Row>
          <Col sm={5}>
            <div className="box_foter">
              <img src={logo} alt="" />
              <p>
                We are committed to excellence in our chosen area of business
              </p>
            </div>
          </Col>
          <Col sm={3}>
            <div className="box_foter"></div>
          </Col>
          <Col sm={4}>
            <div className="box_foter">
              <h4>Get In Touch</h4>
              <ul className="ul_set">
                <li>
                  <Link>{ProjectAddress}</Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={(event) => handlePhoneClick(event, ProjectPhone)}
                  >
                    {ProjectPhone}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={(event) => handleLinkClick(event, ProjectEmail)}
                  >
                    {ProjectEmail}
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="copy_lisght">
        <Container>
          <p>
            © {currentYear} {ProjectName}. All rights reserved.
          </p>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
