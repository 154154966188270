import React from "react";
import { Routes } from "../enum";
import InnerHeader from "../components/InnerHeader";
import { Container } from "react-bootstrap";
import CustomLink from "../components/CustomLink";

function JoinUs() {
  return (
    <div>
      <InnerHeader title="Join Us" />
      <div className="sec_pad">
        <Container>
          <p>
            <strong>Join us as a Field Colleague</strong>
          </p>
          <p>
            You can join us as a Field Colleague from wherever you are, and make
            monthly income. The income you can earn is highly elastic.
          </p>
          <p>
            Under our Field Colleagues arrangement, you will get paid an agreed
            commission monthly on every property you successfully introduce to
            us, and that we successfully bring on to our portfolio. If the
            property you introduced continues to be in our portfolio, you will
            continue to earn. It follows, therefore, that how much you earn,
            will depend very much on the number of properties you successfully
            bring on to our portfolio.{" "}
          </p>
          <p>
            You are not able to act as our filed colleague until you are
            registered with us.{" "}
            <CustomLink linkTo={Routes.Contact}>Contact us</CustomLink> for more
            details.
          </p>
        </Container>
      </div>
    </div>
  );
}

export default JoinUs;
